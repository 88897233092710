<template>
  <el-dialog
    :z-index="1020"
    top="10vh"
    width="1216px"
    :visible="true"
    :close-on-click-modal="false"
    destroy-on-close
    @close="$emit('on-close')"
  >
    <template #title>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column gap-1">
          <h2>{{ $t('orders.emptyCart.orderByStockTaking') }}</h2>
          <p>{{ supplierName }}</p>
        </div>
        <el-tooltip
          :content="$t('orders.stocktakingModal.generalNoticeTooltip')"
          placement="bottom"
          popper-class="stock-taking-notice-tooltip"
        >
          <div class="notice-icon">
            <InformationIcon :size="22" fill="#000000" />
          </div>
        </el-tooltip>
      </div>
    </template>

    <Table
      custom-class="stocktaking-modal-table"
      :columns="tableColumns"
      :data="products"
      :border="true"
      rounded
      hover
      :cell-class="handleCellClass"
    >
      <template #cell-name="{ rowData: { name, sku, disabled, maxStockLevel, bom, available } }">
        <ProductCell
          :name="name"
          :sku="sku"
          :is-recommended="true"
          :is-available="available"
          :max-stock-level="maxStockLevel"
          :uom="getOrderUOM(bom)"
          :disabled="disabled"
        />
      </template>

      <template #cell-lastOrderPrice="{ rowData: { lastOrderPrice, disabled } }">
        <span v-if="lastOrderPrice" :class="{ disabled }">{{ lastOrderPrice }}</span>
        <span v-else class="missing" :class="{ disabled }">{{ MISSING_DATA_TEXT }}</span>
      </template>

      <template #cell-maxStockLevel="{ rowData: { maxStockLevel, disabled } }">
        <div v-if="maxStockLevel" :class="{ disabled }">
          <p>{{ maxStockLevel }}</p>
        </div>
        <p v-else class="missing" :class="{ disabled }">{{ MISSING_DATA_TEXT }}</p>
      </template>

      <template #cell-quantity="{ rowData, rowIndex }">
        <InputMarks
          v-if="typeof rowData.quantity === 'undefined' || rowData.disabled"
          class="input-marks"
          :is-available="rowData.available"
          :is-disabled="rowData.disabled"
          @on-include="enableStocktakingItem(rowData.productId, true)"
          @on-exclude="disableStocktakingItem(rowData.productId)"
        />

        <OrderQuantityControls
          v-else
          :row-data="rowData"
          :has-focus="focusIndex === rowIndex"
          :is-stocktaking="true"
          :debounce="0"
          @on-blur="onBlur"
          @on-quantity-change="updateQuantity"
          @on-move-to-next-press="handleMoveToNext(rowIndex + 1)"
        />
      </template>
    </Table>

    <template #footer>
      <Button
        v-if="productsWithoutDecidedQuantity === 0"
        type="primary"
        class="finish-stocktaking-btn"
        @click="submitStocktaking"
      >
        {{ $t('orders.stocktakingModal.endStocktaking') }}
      </Button>
      <Button v-else type="primary" disabled class="finish-stocktaking-btn">
        {{ $t('orders.stocktakingModal.stocktakingLeft', { productCount: productsWithoutDecidedQuantity }) }}
      </Button>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref } from 'vue';
import { clone, omit } from 'lodash';

import { Table, Button } from '@/modules/core';
import OrderQuantityControls from './OrderQuantityControls';
import ProductCell from './cellRenderers/ProductCell.vue';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { stocktakingColumns } from './tableColumns';
import InputMarks from './InputMarks.vue';
import { InformationIcon } from '@/assets/icons';
import { getOrderUOM } from '@/modules/purchase-management/purchaseManagement';

export default {
  components: {
    Table,
    Button,
    ProductCell,
    OrderQuantityControls,
    InputMarks,
    InformationIcon,
  },
  props: {
    supplierName: { type: String, required: true },
    mappedProducts: { type: Array, required: true },
    orderLocalStorage: { type: Object, required: true },
  },
  emits: ['on-close', 'on-submit', 'on-change'],
  setup(props, { emit }) {
    const focusIndex = ref(-1);
    const tableColumns = stocktakingColumns();

    const products = computed(
      () =>
        props.orderLocalStorage?.items.map((stockTakingItem) => {
          const product = props.mappedProducts.find(({ id }) => stockTakingItem.productId === id);
          if (!product) return stockTakingItem;
          const productWithoutDisabled = omit(product, ['disabled']);

          return {
            ...productWithoutDisabled,
            ...stockTakingItem,
          };
        }) ?? []
    );
    const productsWithoutDecidedQuantity = computed(
      () =>
        props.orderLocalStorage?.items.filter(({ quantity, disabled, productId }) => {
          const shouldFilter = !disabled && typeof quantity === 'undefined';
          const product = props.mappedProducts.find(({ id }) => productId === id);

          return shouldFilter && product?.available;
        }).length ?? 0
    );

    const cloneAndGetIndexOfStocktakingItem = (productId) => {
      const clonedStocktaking = clone(props.orderLocalStorage);
      const productIndexToUpdate = clonedStocktaking.items.findIndex((item) => productId === item.productId);

      return {
        productIndexToUpdate,
        clonedStocktaking,
      };
    };

    const updateQuantity = ({ rowData: { productId }, quantity }) => {
      const { productIndexToUpdate, clonedStocktaking } = cloneAndGetIndexOfStocktakingItem(productId);
      clonedStocktaking.items[productIndexToUpdate].quantity = Number(quantity);

      emit('on-change', clonedStocktaking);
    };

    const onBlur = (rowData) => {
      const clonedStocktaking = clone(props.orderLocalStorage);

      const foundPrevFocusedStocktakingItem = clonedStocktaking.items.find(
        (item) => item.productId === rowData.productId
      );

      const prevVal = foundPrevFocusedStocktakingItem?.quantity?.toString();

      if (!prevVal || prevVal === '0') {
        disableStocktakingItem(foundPrevFocusedStocktakingItem.productId);
      }
    };

    const disableStocktakingItem = (productId) => {
      const { productIndexToUpdate, clonedStocktaking } = cloneAndGetIndexOfStocktakingItem(productId);
      clonedStocktaking.items[productIndexToUpdate].disabled = true;
      clonedStocktaking.items[productIndexToUpdate].quantity = 0;
      emit('on-change', clonedStocktaking);
    };

    const enableStocktakingItem = (productId, isClick = false) => {
      const { productIndexToUpdate, clonedStocktaking } = cloneAndGetIndexOfStocktakingItem(productId);
      if (
        (typeof clonedStocktaking.items[productIndexToUpdate].quantity === 'undefined' &&
          !clonedStocktaking.items[productIndexToUpdate].disabled) ||
        isClick
      ) {
        clonedStocktaking.items[productIndexToUpdate].disabled = false;
        clonedStocktaking.items[productIndexToUpdate].quantity = 1;
        emit('on-change', clonedStocktaking);
      }

      focusIndex.value = productIndexToUpdate;
    };

    const submitStocktaking = () => {
      emit('on-submit');
    };

    const handleMoveToNext = (indexToMove) => {
      const foundProduct = products.value?.[indexToMove];
      let foundProductId = foundProduct?.productId;

      if (indexToMove < products.value.length) {
        if (foundProduct?.disabled || foundProduct.quantity || !foundProduct.available) {
          const foundNextUndecidedProductId = products.value?.find(
            ({ quantity, disabled, available }, index) =>
              available && !disabled && typeof quantity === 'undefined' && index > indexToMove
          )?.productId;

          foundProductId = foundNextUndecidedProductId;
        }

        if (foundProductId) {
          enableStocktakingItem(foundProductId);
        }
      }
    };

    const handleCellClass = (rowIndex) => {
      if (products.value[rowIndex]?.available === false) {
        return 'stocktaking-disabled-col';
      }
    };

    return {
      MISSING_DATA_TEXT,
      products,
      tableColumns,
      productsWithoutDecidedQuantity,
      updateQuantity,
      submitStocktaking,
      enableStocktakingItem,
      disableStocktakingItem,
      focusIndex,
      handleMoveToNext,
      getOrderUOM,
      onBlur,
      handleCellClass,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .table-responsive {
  height: 62vh !important;
}
::v-deep .el-dialog__close {
  font-weight: bold;
  color: black !important;
  &:hover {
    background: #ecf0f3;
    color: black !important;
  }
}
::v-deep .el-dialog {
  border-radius: 0.5rem;
}
::v-deep .el-dialog__footer {
  padding-top: 0px;
}
::v-deep .el-dialog__headerbtn {
  font-size: 22px;
  top: 19px;
}

::v-deep {
  .input-marks {
    width: 110px;

    & * > {
      cursor: pointer !important;
    }
  }
}

tr {
  .actions {
    display: none;
  }

  &:hover .actions {
    display: inline;
    height: 2rem;
    padding: 0px 12px;
  }
}

.finish-stocktaking-btn {
  height: 2.375rem;
}

.disabled {
  color: $typography-secondary;
}

.notice-icon {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-top: -0.1rem;

  &:hover {
    border-radius: 0.25rem;
    background: #e3e4e6;
  }
}
</style>
<style lang="scss">
.stocktaking-modal-table {
  tr {
    &:hover {
      .order-quantity-product-latest-order-data {
        display: block !important;
      }
    }
  }
}

.el-tooltip__popper.stock-taking-notice-tooltip {
  line-height: 140%;
  max-width: 275px;
  white-space: normal;
}
.stocktaking-disabled-col * {
  color: #9295a5;
}
</style>
