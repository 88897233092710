<template>
  <span>
    <div class="labels" :class="{ disabled }">
      <span v-if="uom" class="tag uom-tag">
        {{ $t(`tasks.defineProductTask.bomForm.units.${uom}`) }}
      </span>
      <span v-if="maxStockLevel" class="tag max-stock-level-tag">
        {{ $t(`tasks.defineProductTask.bomForm.maxStockLevel`, { maxStockLevel }) }}
      </span>
      <RecommendedProductIcons
        class="recommended-product-icon"
        :is-recommended="isRecommended"
        :is-available="isAvailable"
        :is-recommended-color="disabled ? 'currentColor' : undefined"
      />
    </div>
    <p class="product-name-in-cell" :class="{ disabled }">
      {{ name }}
    </p>
    <p class="product-sku-in-cell" :class="{ disabled }">{{ sku || $t('orderProcessCart.noSku') }}</p>
  </span>
</template>
<script>
import RecommendedProductIcons from '../RecommendedProductIcons';
export default {
  name: 'ProductCell',
  components: { RecommendedProductIcons },
  props: {
    name: { type: String, default: null },
    sku: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    isAvailable: { type: Boolean, default: true },
    isRecommended: { type: Boolean, default: null },
    uom: { type: String, default: '' },
    maxStockLevel: { type: Number, default: 0 },
  },
};
</script>
<style lang="scss" scoped>
.product-name-in-cell {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.product-sku-in-cell {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  color: #9295a5;
  direction: ltr;
}

.labels {
  gap: 0.38rem;
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.4;
  }

  .tag {
    min-width: 2.5rem;
    padding: 0.125rem 0.375rem;
    border-radius: 0.1875rem;
    text-align: center;
    font-family: Rubik;
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.72rem;
    letter-spacing: 0.01125rem;
    text-transform: uppercase;
    height: 16px;

    &.uom-tag {
      background: #46494f;
      color: #ffffff;
    }

    &.max-stock-level-tag {
      background: #e3e4e6;
      color: #1d1e20;
    }
  }

  .recommended-product-icon {
    margin-right: -0.2rem !important;
    margin-top: -0.1rem !important;
  }
}
</style>
