<template>
  <el-tooltip
    :key="tooltipKey"
    class="order-quantity-tooltip"
    :popper-class="getPopperClasses(tooltipActive, hasNoData)"
    placement="top"
    effect="dark"
    :visible-arrow="false"
  >
    <template #content>
      <div class="d-flex flex-column gap-2">
        <div v-if="loading || (!loading && orderItems.length > 0)" class="fw-bold">
          {{ $t('orderProcessCart.latestOrdersTooltip.title') }}
        </div>
        <ProductLatestOrderDateSkeleton v-if="loading" />
        <div v-else>
          <div v-if="orderItems.length === 0" class="no-data">
            {{ $t('orderProcessCart.latestOrdersTooltip.noData') }}
          </div>
          <div v-else class="d-flex flex-column gap-1">
            <div
              v-for="orderItem in orderItems"
              :key="`${orderItem.id}`"
              class="d-flex justify-content-between order-data-row"
            >
              <div>{{ formatDate(orderItem.orderDate) }}</div>
              <div class="d-flex gap-1">
                <div>
                  {{ formatNumberOrDash(orderItem.quantity) }}
                </div>
                <div>x</div>
                <div>
                  {{ formatMoney(orderItem.netAmountPerUnit) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="rowData.alertQuantity" class="alert-quantity d-flex gap-1 align-content-center">
          <div>
            <NoticeFullIcon :size="12" />
          </div>
          <div>
            {{ rowData.alertOverQuantity ? $t('orderProcessCart.quantityOverAverage') : ALERT_QUANTITY_FALLBACK }}
            {{ rowData.alertLessQuantity ? $t('orderProcessCart.quantityLessAverage') : ALERT_QUANTITY_FALLBACK }}
          </div>
        </div>
      </div>
    </template>
    <div
      :class="{ 'alert-quantity': rowData.alertQuantity }"
      @mouseover="hoverTooltipTrigger"
      @mouseleave="() => hoverTooltipTrigger(false)"
    >
      <NewInformationIcon :size="16" />
    </div>
  </el-tooltip>
</template>
<script>
import { computed, ref } from 'vue';

import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/locale/useCurrency';
import { NewInformationIcon, NoticeFullIcon } from '@/assets/icons';

import { useLatestOrderData } from '../compositions/useLatestOrderData';
import {
  formatDate,
  returnDashInsteadOfNull,
  formatCurrencyOrDash,
  formatNumberOrDash,
} from '../../purchase-management/purchaseManagementFormatters';

import ProductLatestOrderDateSkeleton from './ProductLatestOrderDateSkeleton.vue';

const ALERT_QUANTITY_FALLBACK = '';
export default {
  components: {
    NoticeFullIcon,
    NewInformationIcon,
    ProductLatestOrderDateSkeleton,
  },
  props: {
    rowData: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const tooltipActive = ref(false);
    const initialRender = ref(false);
    const tooltipKey = ref(0);

    const { currencyFormat } = useCurrency();
    const { currentTenant } = useTenancy();
    const { orderItems, loading, onResult } = useLatestOrderData(
      computed(() => ({
        businessId: currentTenant.value.id,
        productId: props.rowData.productId,
        limit: 3,
        sortBy: 'date',
        initialRender: initialRender.value,
      }))
    );

    const hasNoData = computed(() => !loading.value && orderItems.value.length === 0);

    onResult(({ data }) => {
      if (data.orderItemsNew.nodes.length < 3) {
        tooltipKey.value += 1;
      }
    });

    const hoverTooltipTrigger = (hoverIn = true) => {
      if (!initialRender.value) {
        initialRender.value = true;
      }

      if (hoverIn) {
        tooltipActive.value = true;
      } else {
        tooltipActive.value = false;
      }
    };

    const getPopperClasses = (tooltipActive, hasNoData) => {
      const classes = [hasNoData ? 'no-data' : 'latest-order'];
      if (!tooltipActive) {
        classes.push('hide-tooltip');
      }

      return classes.join(' ');
    };

    return {
      ALERT_QUANTITY_FALLBACK,
      loading,
      hasNoData,
      orderItems,
      tooltipKey,
      tooltipActive,
      getPopperClasses,
      formatNumberOrDash,
      hoverTooltipTrigger,
      formatDate: (date) => returnDashInsteadOfNull(formatDate(date)),
      formatMoney: computed(() => (number) => formatCurrencyOrDash(number, currencyFormat.value)),
    };
  },
};
</script>
<style lang="scss" scoped>
div.alert-quantity {
  color: #e47e03;
  max-width: 10rem;
  font-weight: 500;
}
.order-quantity-tooltip {
  color: #9295a5;

  &:hover:not(.alert-quantity) {
    color: #1f284d;
  }
}
.order-data-row {
  color: #bbbec2;
}
</style>
<style lang="scss">
.latest-order {
  &.el-tooltip__popper {
    padding: 0.75rem;
    width: 12.75rem;
  }
}
.no-data {
  &.el-tooltip__popper {
    padding: 5px;
    width: 8.5rem;
  }
}
.hide-tooltip {
  &.el-tooltip__popper {
    display: none;
  }
}
</style>
