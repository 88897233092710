<template>
  <span v-if="!isAvailable" :style="{ cursor: 'pointer' }">
    <el-tooltip effect="dark" placement="top" :content="$t('catalog.products.table.availability.toolTip')">
      <Tag type="warning"> {{ $t('catalog.products.table.availability.false') }} </Tag>
    </el-tooltip>
  </span>
  <span v-else :style="{ cursor: 'pointer' }">
    <el-tooltip
      v-if="isRecommended === true"
      effect="dark"
      :content="$t('orderProcessCart.tooltipWhitelist')"
      placement="top"
    >
      <ListCheckIcon :color="isRecommendedColor ?? '#11874F'" width="16px" height="16px" class="mx-1" />
    </el-tooltip>
    <el-tooltip
      v-else-if="isRecommended === false"
      effect="dark"
      :content="$t('orderProcessCart.tooltipBlackList')"
      placement="top"
    >
      <DisabledIcon :color="notRecommendedColor ?? '#E52044'" width="16px" height="16px" class="mx-1" />
    </el-tooltip>
  </span>
</template>
<script>
import { Tag } from '@/modules/core';

import { ListCheckIcon, DisabledIcon } from '@/assets/icons';

export default {
  name: 'RecommendedProductIcons',
  components: {
    Tag,
    ListCheckIcon,
    DisabledIcon,
  },
  props: {
    isRecommended: { type: Boolean, required: false },
    isRecommendedColor: { type: String, required: false, default: undefined },
    notRecommendedColor: { type: String, required: false, default: undefined },
    isAvailable: { type: Boolean, default: true },
  },
};
</script>
